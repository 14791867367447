<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="page--login d-flex justify-center align-center">
          <div class="form-container">
            <v-card class="pa-10" color="rgba(255, 255, 255, .8)">
              <v-form ref="form">
                <v-row>
                  <v-col class="text-center">
                    <img
                      src="/img/brasao_64.png"
                      alt="Brasão do state de Pernambuco"
                    />
                    <br />
                    <h1
                      class="subtitle-1 blue--text text--darken-4"
                      v-text="$appName || 'Acessar o Sistema'"
                    ></h1>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.username"
                      solo
                      hide-details="auto"
                      placeholder="CPF"
                      v-mask="'###########'"
                      :rules="[(v) => $required(v, 'CPF é obrigatório')]"
                      @keyup.enter="enter"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.password"
                      type="password"
                      solo
                      placeholder="Senha"
                      hide-details="auto"
                      :rules="[
                        (v) => $required(v, 'Senha é obrigatória'),
                        (v) => $max(v, 20),
                      ]"
                      @keyup.enter="enter"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="$recaptchaDev">
                  <v-col>
                    <small>
                      <avp-recaptcha @recaptchaVerified="verified = $event" />
                    </small>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      block
                      color="success"
                      :loading="loading"
                      @click.prevent="enter"
                    >
                      Entrar
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0 mt-3 text-center">
                    <small>
                      <a @click="esqueciMinhaSenha()"> Esqueci minha senha ou não tenho senha cadastrada </a>
                    </small>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
    <MudarSenhaComponent
      :visible="trocarSenha.primeiroAcesso"
      :content="trocarSenha"
      @onClose="trocarSenha.primeiroAcesso = false"
    ></MudarSenhaComponent>
  </v-container>
</template>

<script>
import MudarSenhaComponent from "components/MudarSenhaComponent.vue";
import { LOGIN } from "store/modules/auth";
import { CLEAR } from "store";
import { TrocarSenha } from '~/commons/classes';

export default {
  components: { MudarSenhaComponent },
  data: () => ({
    loading: false,
    verified: false,
    trocarSenha: TrocarSenha.Build(),
    user: {
      username: undefined,
      password: undefined,
    },
  }),
  beforeMount() {
    this.hidePageLoading();
    this.$store.dispatch(CLEAR);
  },
  methods: {
    enter() {
      if (this.$validarFormulario()) {
        if (!this.verified && this.$recaptchaDev) {
          return this.$notifyWarning("Conclua o reCAPTCHA para continuar.");
        }
        this.loading = true;
        this.$realizarLogin({
          nomeDeUsuario: this.user.username,
          senha: this.user.password,
        }).then(({ data }) => {
          this.trocarSenha = new TrocarSenha({
            ...data,
            primeiroAcesso: data.trocarSenha
          });
          // Se não for o fluxo de mudar senha
          if (!this.trocarSenha.primeiroAcesso) {
            this[LOGIN](data).then(() => {
              this.$atualizarCacheMenu().then(() => {
                this.$toHome();
              });
            });
          }
        }).catch(() => this.$store.dispatch(CLEAR))
        .finally(() => this.loading = false);
      }
    },
    esqueciMinhaSenha() {
      // window.open("https://www.senhaservidor.sad.pe.gov.br/ControleSenha/senha_edit.jsf", "_blank");
      this.$router.push({ path: "/EsqueciASenha" });
    },
    naoTenhoSenhaCadastrada() {
      this.$notifyError("Você será redirecionado ao Portal do Servidor, para cadastro da senha de acesso.");
      setTimeout(() => {
        window.open("https://www.senhaservidor.sad.pe.gov.br/ControleSenha/servidor_edit.jsf", "_blank");
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.page--login {
  min-height: calc(100vh - 24px) !important;
  .form-container {
    form {
      min-width: 250px;
      a {
        text-decoration: none;
      }
    }
  }
}
</style>
