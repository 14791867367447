var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "page-wrapper"
    }
  }, [_vm.visibleBackgroundImage ? _c('img', {
    attrs: {
      "alt": "Imagem de fundo",
      "id": "background-image",
      "src": _vm.backgroundImageSrc
    }
  }) : _vm._e(), _c('v-main', {
    attrs: {
      "id": "main"
    }
  }, [_c('vue-page-transition', {
    attrs: {
      "name": "fade-in-right"
    }
  }, [_c('router-view', {
    staticClass: "page"
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }