var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "page--login d-flex justify-center align-center"
  }, [_c('div', {
    staticClass: "form-container"
  }, [_c('v-card', {
    staticClass: "pa-10",
    attrs: {
      "color": "rgba(255, 255, 255, .8)"
    }
  }, [_c('v-form', {
    ref: "form"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center"
  }, [_c('img', {
    attrs: {
      "src": "/img/brasao_64.png",
      "alt": "Brasão do state de Pernambuco"
    }
  }), _c('br'), _c('h1', {
    staticClass: "subtitle-1 blue--text text--darken-4",
    domProps: {
      "textContent": _vm._s(_vm.$appName || 'Acessar o Sistema')
    }
  })])], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '###########',
      expression: "'###########'"
    }],
    attrs: {
      "solo": "",
      "hide-details": "auto",
      "placeholder": "CPF",
      "rules": [function (v) {
        return _vm.$required(v, 'CPF é obrigatório');
      }]
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.enter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "password",
      "solo": "",
      "placeholder": "Senha",
      "hide-details": "auto",
      "rules": [function (v) {
        return _vm.$required(v, 'Senha é obrigatória');
      }, function (v) {
        return _vm.$max(v, 20);
      }]
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.enter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  })], 1)], 1), _vm.$recaptchaDev ? _c('v-row', [_c('v-col', [_c('small', [_c('avp-recaptcha', {
    on: {
      "recaptchaVerified": function ($event) {
        _vm.verified = $event;
      }
    }
  })], 1)])], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "success",
      "loading": _vm.loading
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.enter.apply(null, arguments);
      }
    }
  }, [_vm._v(" Entrar ")])], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0 mt-3 text-center"
  }, [_c('small', [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.esqueciMinhaSenha();
      }
    }
  }, [_vm._v(" Esqueci minha senha ou não tenho senha cadastrada ")])])])], 1)], 1)], 1)], 1)])])], 1), _c('MudarSenhaComponent', {
    attrs: {
      "visible": _vm.trocarSenha.primeiroAcesso,
      "content": _vm.trocarSenha
    },
    on: {
      "onClose": function ($event) {
        _vm.trocarSenha.primeiroAcesso = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }